import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination  } from 'swiper';
import gsap from "gsap"

import ResumeGif from '../images/resume.gif'
import UBL from '../images/UBLIMAGE.PNG'
import Jogo from '../images/JogoGif.gif'
import MarioGif from '../images/MarioGif.gif'
// import Pokemon from '../images/pokemon.PNG'
import Invaders from '../images/InvadersGif.gif'
import Cat from '../images/rocketGif.gif'
import Github from '../images/github-icon-white-6.jpg'
import MRP from '../images/mrp.png'


// import LinguistAssist from '../images/LA.PNG'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const ProjectSlides = () => {
  let moveMath = 0

  // const showGifOnHover = (nameOfImg) => {
  //   if (nameOfImg === 'mario'){
  //     gsap.to('#marioProjectImg', {duration: 0, display: 'none', opacity: 0 })
  //     gsap.to('#marioProjectGif', {duration: 0, display: 'block', opacity: 1 })
  //   }
  // }

  // const hideGifOnHover = (nameOfImg) => {
  //   if (nameOfImg === 'mario'){
  //     gsap.to('#marioProjectGif', {duration: 0, display: 'none', opacity: 0 })
  //     gsap.to('#marioProjectImg', {duration: 0, display: 'block', opacity: 1 })
  //   }
  // }

  const slideTiggerAnimations = () => {
    moveMath += 20
    if (moveMath < 100){ 
      gsap.to("#rocketDesktop", {x: `${moveMath}vw`})
      gsap.to("#rocketMobile", {x: `${moveMath }vw`})
    }
    else if (moveMath === 100){
      moveMath = 0
      gsap.to("#rocketDesktop", {x: 0, visibility: 'none', duration: 0})
      gsap.to("#rocketMobile", {x: 0, visibility: 'none', duration: 0})
    }
  }


  return (
    <div id='projectsSection' className="opacity-0 w-[96vw] text-white mt-[1rem] ">
      <div>
      <Swiper
          // install Swiper modules
          modules={[Pagination, Navigation]}
          breakpoints={{
              800: {
                width: 800,
                slidesPerView: 1.2,
              }
            }}
          spaceBetween={0}
          slidesPerView={1}
          scrollbar={{ draggable: true }}
          navigation
          onSlideChange={() => slideTiggerAnimations()}
          
          >
          <SwiperSlide>
            <div className="w-[95%] border-2 border-white  ml-[1rem] md:ml-[2rem] lg:ml-[12rem] h-[34rem] smh-[32rem]">
                <div className='flex justify-center'>
                  <img src={Invaders} className="p-2 w-[90%] margin" alt="Resume Maker" />
                </div>
                <p className="mt-[.5rem] pl-[.5rem] mb-2 text-[#61DBFB]">{"<"} Invaders {"/>"}</p>
                <p className="mt-[.5rem] mb-[1rem] pl-[.5rem] pr-[.5rem]">Stop the Alien Invasion in my JavaScript game Invaders. Use your mouse and keyboard to move around, shoot, dodge, and level up. Have your speakers on and have fun! (still building the last few levels but game is playable)</p>
                <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">Technologies: JavaScript, Vue.js, Node.js., Canvas</p>
                <a href="https://invaders-canvas.herokuapp.com/" target="_blank"  rel="noreferrer" className="pl-[.5rem] hover:cursor-pointer hover:text-[#61DBFB]">Play Game</a>
              </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="w-[95%] border-2 border-white  ml-[1rem] md:ml-[2rem] lg:ml-[12rem] h-[34rem] smh-[32rem]">
                <div className='flex justify-center'>
                  <img src={MRP} className="p-2 w-[80%] margin" alt="Resume Maker" />
                </div>
                <p className="mt-[.5rem] pl-[.5rem] mb-2 text-[#61DBFB]">{"<"} Movie Rating Predictor {"/>"}</p>
                <p className="mt-[.5rem] mb-[1rem] pl-[.5rem] pr-[.5rem]">This program predicts how a user would rate a given movie by using Machine Learning models Linear Regression and Random Forest Regressor.
It uses movie ratings data to train the model and provides a prediction score based on the user's previous ratings.</p>
                <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">
                  Technologies: Python, pandas, numpy, sklearn
                  </p>
                <a href="https://github.com/ChimentiMatt/movie-rating-predictor-machine-learning" target="_blank"  rel="noreferrer" className="pl-[.5rem] hover:cursor-pointer hover:text-[#61DBFB]">Visit Repository</a>
              </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-[95%] border-2 border-white  ml-[1rem] md:ml-[2rem] lg:ml-[12rem] h-[34rem] smh-[32rem]">
                <div className='flex justify-center'>
                  <img src={ResumeGif} className="p-2 w-[90%] margin" alt="Resume Maker" />
                </div>
                <p className="mt-[.5rem] pl-[.5rem] mb-2 text-[#61DBFB]">{"<"} Resume Maker {"/>"}</p>
                <p className="mt-[.5rem] mb-[1rem] pl-[.5rem] pr-[.5rem]">A resume creation tool. Designed to allow the easy creation of resumes on a single page format without hitting a paywall, or being unable to see what you are creating. Change templates at any time and see their resume being built in real time.</p>
                <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">Technologies: JavaScript, React, Node.js.</p>
                <a href="https://resumemaker.dev/" target="_blank"  rel="noreferrer" className="pl-[.5rem] hover:cursor-pointer hover:text-[#61DBFB]">Visit Application</a>
              </div>
          </SwiperSlide>
          <SwiperSlide>
              <div className="w-[95%] border-2 border-white ml-[1rem] md:ml-[2rem] lg:ml-[12rem] h-[34rem] smh-[32rem]">
                  <img src={MarioGif} className="p-2" alt="Mario Music MERN" />
                  <p className="mt-[.5rem] text-[#61DBFB] pl-[.5rem]">{"<"} Mario Composer {"/>"}</p>
                  <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">A MERN full stack application that allows users to build songs by placing notes on the music sheet. Change note sound, tempo, note length and more. Save your song with a user's system (not required). 11/20 removed backend and save feature to save monthly expenses. </p>
                  <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">Technologies: JavaScript, React, Node.js, Mongo DB, Express and Tailwind.</p>
                  <a href="https://mario-composer-ui.herokuapp.com/" target="_blank" rel="noreferrer" className="  pl-[.5rem] hover:cursor-pointer  hover:text-[#61DBFB]">Visit Application </a>
              </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="w-[95%] border-2 border-white  ml-[1rem] md:ml-[2rem] lg:ml-[12rem] h-[34rem] smh-[32rem]">
                <img src={UBL} className="p-2" alt="Ballerz" />
                <p className="mt-[.5rem] pl-[.5rem] mb-2 text-[#61DBFB]">{"<"} Ballerz: Skill Calculator {"/>"}</p>
                <p className="mt-[.5rem] mb-[0rem] pl-[.5rem] pr-[.5rem]">A companion app to the Ballerz Game. The Skill Converter is a full stack application that allows a user to search 
                tens of thousands of ballerz/Sneakerz and compose a team of five members. On calling the API, crunches heavy calculations in state based off 30+ stat lines. Client now updates and . </p>
                <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">Technologies: JavaScript, React, Node.js, Mongo DB, Express and Tailwind.</p>

                <a href="https://bbl.ballerz.com/" target="_blank"  rel="noreferrer"  className="  pl-[.5rem] hover:cursor-pointer  hover:text-[#61DBFB]">Visit Application </a>
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div className="w-[95%] border-2 border-white ml-[1rem] md:ml-[2rem] lg:ml-[12rem] h-[34rem] smh-[32rem]">
                <img src={Jogo} className="p-2" alt="jogoLabs" />
                <p className="mt-[.5rem] pl-[.5rem] mb-2 text-[#61DBFB]">{"<"} Jogo Labs {"/>"}</p>
                <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">A front-end heavy business website where I worked with a designer from Meta to make a fluid, seamless website for their advertising.  The business owner removed the deployed version after taking a Sr. Director of Product role for Walmart software development.</p>
                <p className="mt-[.5rem] pl-[.5rem] mb-[.5rem] pr-[.5rem]">Technologies: JavaScript, React, Node.js.</p>

              </div>
          </SwiperSlide>
          {/* <SwiperSlide>
              <div className="w-[95%] border-2 border-white ml-[1rem] md:ml-[2rem]  lg:ml-[12rem] h-[34rem] smh-[32rem]">
                  <img src={Pokemon} className="p-2" alt="Pokemon Simulator" />
                  <p className="mt-[.5rem] pl-[.5rem] text-[#61DBFB]">{"<"} Pokémon! Simulator {"/>"}</p>
                  <p className="mt-[.5rem] pl-[.5rem] mb-[1.5rem] pr-[.5rem]">Play a mock version of the Pokemon Gameboy game built using only vanilla Javascript. Use Charmander to battle and catch Pokémon. See if you can catch them all. Your progress is tracked in your Pokédex. How to play: Use the Arrow Keys and Enter or click on the Gameboy itself. (non business project, not currently paying for deployment) </p>
              </div>
          </SwiperSlide> */}
      </Swiper>

      {/* ----Mobile and smalls screen rocket. Desktop is in projects next to My Showcase text---- */}
      <div className='flex justify-between overflow-hidden mt-[2rem] mb-[1.5rem] w-[100vw] h-[0rem]'>
        <img id="rocketMobile" className='relative h-[5rem] md:ml-[12rem] rotate-[45deg] lg:hidden lg:opacity-0' src={Cat} alt="rocket drawing" />
      </div>

      <div className='flex flex-col justify-center items-center text-center text-white text-[2rem] pl-[1rem] pr-[1rem] md:pl-0 md-pr-0 md:text-[3rem] mt-[15rem]'>
        <h1>Visit my Github</h1>
        <a href="https://github.com/ChimentiMatt" target="_blank" rel="noreferrer" ><img className='w-[rem]' src={Github} alt='github'/></a>
      </div>

    </div>

  </div>
  )
}

export default ProjectSlides