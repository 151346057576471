import { useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

import books from "../images/books.gif"
import brain from "../images/brain.webp"


const Skills = () => {
    const targets = [
        'ed_E', 'ed_d' ,'ed_u', 'ed_c', 'ed_a', 'ed_t', 'ed_i', 'ed_o', 'ed_n'
    ]

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        
        ScrollTrigger.create({
            trigger: "#ed_E",
            onEnter: textAppearing,
            start: "center 600",
            end: "center 600 ",
            // markers: true
        })
    }, [])

    const textAppearing = () => {
        gsap.to('#educationPage', {opacity: 1})
        let time = 0
        for (const element of targets){
            let tl = gsap.timeline({repeat: 0})
            tl.to("#" + element, { delay: time, opacity: 1, ease: 'none', transition: .2})
            tl.to("#" + element, { delay: time, opacity: 1, ease: 'none'})
            
            let tl2 = gsap.timeline({repeat: 0})
            tl2.to("#" + element, { delay: time, scale: 1.2, ease: 'none', transition: .2})
            tl2.to("#" + element, { scale: 1, ease: 'none'})
            
            time += .05
        }
    }

  return (
    <div id="skillsTarget" className="flex h-[60rem] items-center relative left-0 min-h-[60rem] md:mt-[5rem] md:ml-[2rem] ">
        <div id="educationPage" className="opacity-0 flex flex-col ">
            <div className="flex flex-col pl-[1rem] lg:pl-[10rem]">
                <div className="flex leading-[140%] text-[3.3rem]  md:mt-[0rem] md:text-[5rem] xl:text-[5rem] xl:leading-[120%]">
                    <h1 id="ed_E" className="opacity-0 text-white hover:text-[#61DBFB] ">E</h1>
                    <h1 id="ed_d" className="opacity-0 text-white hover:text-[#61DBFB] ">d</h1>
                    <h1 id="ed_u" className="opacity-0 text-white hover:text-[#61DBFB] ">u</h1>
                    <h1 id="ed_c" className="opacity-0 text-white hover:text-[#61DBFB] ">c</h1>
                    <h1 id="ed_a" className="opacity-0 text-white hover:text-[#61DBFB] ">a</h1>
                    <h1 id="ed_t" className="opacity-0 text-white hover:text-[#61DBFB] ">t</h1>
                    <h1 id="ed_i" className="opacity-0 text-white hover:text-[#61DBFB] ">i</h1>
                    <h1 id="ed_o" className="opacity-0 text-white hover:text-[#61DBFB] ">o</h1>
                    <h1 id="ed_n" className="opacity-0 text-white hover:text-[#61DBFB] ">n</h1>
                </div>

                <div className="text-white mt-2 md:text-[1.5rem] xl:mt-5 xl:text-[2rem]">
                    <h1 className="text-[#61DBFB] ">Masters of Science Software Development</h1>
                    <p className="text-[1rem]">Grand Canyon University</p>
                    <p className="text-[1rem]">Aug 2022 to March 2024</p>
                    <p className="text-[1rem]">GPA 3.95</p>

                    <h1 className="text-[#61DBFB] pt-1">Bridge Program to Masters</h1>
                    <p className="text-[1rem]">Grand Canyon University</p>
                    <p className="text-[1rem]">Nov 2021 to Aug 2022</p>
                    <p className="text-[1rem]">GPA 4.0</p>

                    <h1 className="text-[#61DBFB] pt-1">Bachelors of Arts English Literature</h1>
                    <p className="text-[1rem]">Oregon State University</p>
                    <p className="text-[1rem]">2006 to 2019</p>

                    <h1 className="text-[#61DBFB] pt-1">Full Stack Bootcamp</h1>
                    <p className="text-[1rem]">PDX Code Guild</p>
                    <p className="text-[1rem]">Oct 2019 to Feb 2020</p>

                    <h1 className="text-[#61DBFB] pt-1">Mern Stack Bootcamp</h1>
                    <p className="text-[1rem]">PDX Code Guild</p>
                    <p className="text-[1rem]">Oct Feb to Jul 2021</p>

                    <h1 className="text-[#61DBFB] pt-[3rem]">Awards/Certificates</h1>     
                    <p className="text-[1rem]">Certified Tester, Foundation Level: ISTQB 2022</p>
                    <p className="text-[1rem]">Honor Society: Alpha Chi</p>

                    <div>
                        {/* <img id="maths" className="absolute lg:left-[60%] left-[7rem] h-[10rem] bottom-[5rem] md:bottom-[10rem] z-10 " src={maths} alt="dancing duck"/> */}
                        <img id="brain" className="absolute lg:left-[50%] left-[12rem] h-[7rem] lg:h-[10rem] bottom-[15rem] md:bottom-[10rem] md:left-[25rem] z-10" src={brain} alt="brain eating books"/>
                        <img id="books" className="absolute opacity-0 lg:left-[60%] left-[5rem] h-[7rem] lg:h-[10rem] bottom-[-0.1rem] md:bottom-[25rem] md:left-[31rem] z-10 " src={books} alt="book flipping pages"/>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Skills