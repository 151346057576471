import { Link } from "react-scroll";
import GitHubIcon from "../images/GitHub.png";
import LinkedInIcon from "../images/linkedin.png";

const Sidebar = ({ handleClick }) => {
  return (
    <div
      className="
        hidden
        z-[100]
        fixed
        flex-col 
        w-[8rem]
        h-screen
        bg-black
        border-r-[2px]
        border-white
        lg:flex
        "
    >
      <div className="bg-black border-b-[2px] border-white text-white text-center hover:bg-white hover:cursor-pointer hover:text-black">
          <Link to="homePage" spy={true} smooth={true}>
            <div className="leading-7 text-[1.5rem] pb-[1.5rem] pt-[1.5rem] ">
              <p className="">{"<"} Matter</p>
              <p>of</p>
              <p>Code {"/>"}</p>
            </div>
          </Link>
      </div>

      <div className="">
        <Link to="aboutPage" spy={true} smooth={true}>
            <ul
              className="text-center
                text-[1rem]
                text-white
                pt-[1rem]
                pb-[1rem]
                border-t-[1px]
                border-stone-800
                hover:cursor-pointer
                hover:bg-white
                hover:text-black
                "
            >
                About
            </ul>
          </Link>
          <Link to="projectsPage" spy={true} smooth={true}>
            <ul
              className="
                text-center
                text-[1rem]
                text-white
                
                pt-[1rem]
                pb-[1rem]
                border-t-[1px]
                hover:cursor-pointer
                border-stone-800
                hover:bg-white
                hover:text-black
                "
            >
            Projects
              </ul>
          </Link>
          <Link to="skillsTarget" spy={true} smooth={true}>
            <ul className="
                    text-center
                    text-[1rem]
                    text-white
                    pt-[1rem]
                    pb-[1rem]
                    border-t-[1px]
                    hover:cursor-pointer
                    border-stone-800
                    hover:bg-white
                    hover:text-black
                    "
                    > 
                    Skills
                </ul>
            </Link>
            <Link to="educationPage" spy={true} smooth={true}>
              <ul className="
                  text-center
                  text-[1rem]
                  text-white
                  pt-[1rem]
                  pb-[1rem]
                  border-t-[1px]
                  hover:cursor-pointer
                  border-stone-800
                  hover:bg-white
                  hover:text-black
                  "
                  > 
                  Education
              </ul>
            </Link>
            <Link to="contactPage" spy={true} smooth={true}>
              <ul
                className="
                      text-center
                      text-[1rem]
                      text-white
                      pt-[1rem]
                      pb-[1rem]
                      border-t-[1px]
                      border-b-[1px]
                      hover:cursor-pointer
                      border-stone-800
                      hover:bg-white
                      hover:text-black
                      "
              >
              Contact
              </ul>
            </Link>
        <div
          className="
                flex
                content-center
                justify-center
                list-none
                pt-[1rem]"
        >
          <a
            href="https://linkedin.com/in/matt-chimenti-4001834b"
            target="blank"
            className="
                    text-center
                    text-[1rem]
                    text-white
                    hover:cursor-pointer
                    hover:invert
                    "
          >
            <img
              className="h-5 hover:bg-black hover:rounded-[20%]"
              src={LinkedInIcon}
              alt="linkedin"
            />
          </a>
          <a
            href="https://github.com/ChimentiMatt"
            target="blank"
            className="
                    text-center
                    text-[1rem]
                    text-white
                    hover:cursor-pointer
                    hover:invert
                    pl-2
                    "
          >
            <img
              className="h-5 hover:bg-black hover:rounded-lg"
              src={GitHubIcon}
              alt="github"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
